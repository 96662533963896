import React, { useEffect, useState, useRef } from 'react';
import { PatternFormat } from 'react-number-format';
import { AttendanceRecord } from '../../../common/Interface/AttendanceRecord';
import {
  MASK_CHAR,
  NO_DATA_PLACEHOLDER,
  FORMAT_HHMM,
  DEFAULT_VALUE_HHMM,
  LEAVE,
  LEAVE_DISPLAY_VALUES,
} from '../../../common/constants';
import {
  isValidTime,
  timeDisplayFormat,
  calculateShiftTime,
  calculateWorkingHour,
  calculateBreakTimeOfDay,
  formatHHMM,
  FormatDate,
  isDayOff,
  isWorkday,
  parseHHMMToDate,
  checkDisabledDate,
} from '../../../utils/Utils';
import { TextField } from '@mui/material';
import { SAVE_DAILY_RECORDS } from '../../../graphql/mutations';
import client from '../../../graphql/apolloClient';
import { useMutation } from '@apollo/client';
import LoadingIndicator from '../../../components/Loading/Loading';
import styles from './AttendanceMonthTableTR.module.css';

interface AttendanceMonthOfDayProps {
  record: AttendanceRecord;
  onValidDataChange: () => void;
  isManagerPage?: boolean;
}

const AttendanceMonthTableTR: React.FC<AttendanceMonthOfDayProps> = ({
  record,
  onValidDataChange: onValidDataChange,
  isManagerPage = false,
}) => {
  const [saveDailyRecords] = useMutation(SAVE_DAILY_RECORDS, { client: client });

  // StartTimeDisplayValue
  const [startTimeHHMM, setStartTimeDisplayValue] = useState<string | null>(
    record.StartTime === null ? null : formatHHMM(record.StartTime, record.Date),
  );
  const prevStartTimeDisplayValue = useRef(startTimeHHMM);

  // EndTimeDisplayValue
  const [endtTimeHHMM, setEndTimeDisplayValue] = useState<string | null>(
    record.EndTime === null ? null : formatHHMM(record.EndTime, record.Date),
  );
  const prevEndTimeDisplayValue = useRef(endtTimeHHMM);

  // Invalid flag
  const [isInvalidStartTime, setIsInvalidStartTime] = useState<boolean>(
    shouldCheckTimeValidity(record.Date, record.Leave) && startTimeHHMM === null,
  );
  const [isInvalidEndTime, setIsInvalidEndTime] = useState<boolean>(
    shouldCheckTimeValidity(record.Date, record.Leave) && endtTimeHHMM === null,
  );

  // Note
  const [noteValue, setNoteValue] = useState<string | null>(record.Note);
  const prevNoteValue = useRef(noteValue);

  // etc
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const isDisabled = checkDisabledDate(record.Date, isManagerPage);

  // Leave
  const [leaveValue, setSelectedLeave] = useState<string>(record.Leave ? record.Leave : LEAVE.NULL);
  const prevLeaveValue = useRef(leaveValue);

  // Invalid flagsの再評価のためのuseEffect
  useEffect(() => {
    setIsInvalidStartTime(
      shouldCheckTimeValidity(record.Date, leaveValue) && startTimeHHMM === null,
    );
    setIsInvalidEndTime(shouldCheckTimeValidity(record.Date, leaveValue) && endtTimeHHMM === null);
  }, [leaveValue, startTimeHHMM, endtTimeHHMM]);

  /**
   * 時刻入力が必須かどうかをチェックする
   * @param date - 対象日付
   * @param leave - 休暇種別
   * @returns
   * - true: 時刻入力が必須（以下の条件を両方満たす場合）
   *   1. 平日である
   *   2. 休暇が未設定 または 半休（午前・午後）である
   * - false: 時刻入力が不要
   */
  function shouldCheckTimeValidity(date: Date, leave: string | null): boolean {
    return (
      isWorkday(date) &&
      (!leave ||
        leave === LEAVE.NULL ||
        [LEAVE.MORNING_PAID_LEAVE, LEAVE.AFTERNOON_PAID_LEAVE].includes(leave as LEAVE))
    );
  }

  const handleBlur = () => {
    // 前回の値と現在の値を比較
    if (
      prevStartTimeDisplayValue.current !== startTimeHHMM ||
      prevEndTimeDisplayValue.current !== endtTimeHHMM ||
      prevNoteValue.current !== noteValue ||
      prevLeaveValue.current !== leaveValue
    ) {
      // 値が変更されている場合のみサーバーに同期
      syncWithServer();
    }
  };

  const syncWithServer = async () => {
    {
      const [startValid, startDate] = parseHHMMToDate(startTimeHHMM, record.Date);
      const startTime = startValid ? startDate.toISOString() : null;

      const [endValid, endDate] = parseHHMMToDate(endtTimeHHMM, record.Date);
      const endTime = endValid ? endDate.toISOString() : null;
      const note = noteValue ? noteValue : null;

      const leave = leaveValue === LEAVE.NULL ? null : leaveValue;

      try {
        setDataLoading(true);
        console.log(`record.UserID: ${record.UserID}`);
        const response = await saveDailyRecords({
          variables: {
            params: [
              {
                UserID: record.UserID,
                Date: record.Date,
                Leave: leave,
                StartTime: startTime,
                EndTime: endTime,
                Note: note,
              },
            ],
          },
        });

        if (response.data.saveDailyRecords && response.data.saveDailyRecords.length > 0) {
          // 前回の値を更新
          prevStartTimeDisplayValue.current = startTimeHHMM;
          prevEndTimeDisplayValue.current = endtTimeHHMM;
          prevNoteValue.current = noteValue;
          prevLeaveValue.current = leaveValue;
        } else {
          console.log(
            'Error saving Shift In Time. Please retry. Checking the database for updates.',
          );
        }

        onValidDataChange();
      } catch (error) {
        console.log('Shift-In Catch: Error saving Shift In Time.');
        console.log(error);
      } finally {
        setDataLoading(false);
      }
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <>
      <tr
        className={`${isDayOff(record.Date) ? 'text-red-500' : 'text-black'} ${isDisabled ? styles['disabled-row'] : ''}`}
      >
        <td className="px-4 py-2 text-center font-semibold ">{FormatDate(record.Date)}</td>
        <td className="px-4 py-2 text-center font-semibold text-black">
          <select
            value={leaveValue}
            onChange={(e) => setSelectedLeave(e.target.value)}
            onBlur={handleBlur}
            disabled={isDisabled}
          >
            {Object.keys(LEAVE).map((leaveKey) => (
              <option key={leaveKey} value={leaveKey}>
                {LEAVE_DISPLAY_VALUES[leaveKey]}
              </option>
            ))}
          </select>
        </td>
        <td
          className={`px-4 py-2 text-center font-semibold text-black ${isInvalidStartTime ? 'bg-red-300' : ''}`}
        >
          <PatternFormat
            className="w-[60px]"
            format={FORMAT_HHMM}
            valueIsNumericString={true}
            mask={MASK_CHAR}
            value={startTimeHHMM ? startTimeHHMM : DEFAULT_VALUE_HHMM}
            onFocus={handleFocus}
            onValueChange={(values) => {
              if (values.value === '') {
                setStartTimeDisplayValue(null);
                setIsInvalidStartTime(false);
                return;
              }
              const isValid = isValidTime(values.formattedValue);
              setIsInvalidStartTime(!isValid);
              if (isValid) {
                setStartTimeDisplayValue(values.value);
              }
            }}
            onBlur={handleBlur}
            disabled={isDisabled}
          />
        </td>
        <td
          className={`px-4 py-2 text-center font-semibold text-black ${isInvalidEndTime ? 'bg-red-300' : ''}`}
        >
          <PatternFormat
            className="w-[60px]"
            format={FORMAT_HHMM}
            valueIsNumericString={true}
            mask={MASK_CHAR}
            onFocus={handleFocus}
            value={endtTimeHHMM ? endtTimeHHMM : DEFAULT_VALUE_HHMM}
            onValueChange={(values) => {
              if (values.value === '') {
                setEndTimeDisplayValue(null);
                setIsInvalidEndTime(false);
                return;
              }
              const isValid = isValidTime(values.formattedValue);
              setIsInvalidEndTime(!isValid);
              if (isValid) {
                setEndTimeDisplayValue(values.value);
              }
            }}
            onBlur={handleBlur}
            disabled={isDisabled}
          />
        </td>
        <td className=" px-4 py-2 text-center font-semibold text-black">
          {record.StartTime !== null && record.EndTime !== null
            ? timeDisplayFormat(calculateShiftTime(record.StartTime, record.EndTime))
            : NO_DATA_PLACEHOLDER}
        </td>
        <td className=" px-4 py-2 text-center font-semibold text-black">
          {record.StartTime !== null && record.EndTime !== null
            ? timeDisplayFormat(calculateBreakTimeOfDay(record.StartTime, record.EndTime))
            : NO_DATA_PLACEHOLDER}
        </td>
        <td className=" px-4 py-2 text-center font-semibold text-black">
          {record.StartTime !== null && record.EndTime !== null
            ? timeDisplayFormat(calculateWorkingHour(record.StartTime, record.EndTime))
            : NO_DATA_PLACEHOLDER}
        </td>
        <td className=" px-4 py-2 text-center font-semibold text-black">
          <TextField
            variant="outlined"
            size="small"
            className=" px-4 py-2 text-center font-semibold text-black"
            value={noteValue ? noteValue : ''}
            onChange={(e) => setNoteValue(e.target.value)}
            onBlur={handleBlur}
            disabled={isDisabled}
          />
        </td>
        <td>{dataLoading ? <LoadingIndicator /> : ''}</td>
      </tr>
    </>
  );
};

export default AttendanceMonthTableTR;

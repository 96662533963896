import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import ReactGA from 'react-ga';

// Importing App component
import App from './App';

// Importing for CSS styling
import './index.css';
import './css/satoshi.css';
import 'jsvectormap/dist/css/jsvectormap.css';

// Importing Flatpickr for date and time picking.
import 'flatpickr/dist/flatpickr.min.css';

// Importing AWS-Amplify and configuration
import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

// Configuring AWS-Amplify
Amplify.configure(awsConfig);

// Initializing Google Analyzer tracking
ReactGA.initialize('G-YJ7WL8DDZ9');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
);

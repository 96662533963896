import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');

const DigitalClock: React.FC = () => {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(dayjs());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="w-full px-2">
      <div className="text-center mb-2">
        <div className="text-4xl text-black">{time.format('HH:mm:ss')}</div>
        <div className="text-lg text-black">
          {time.format('YYYY年MM月DD日')} {time.format('dddd')}
        </div>
      </div>
    </div>
  );
};

export default DigitalClock;

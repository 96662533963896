import React from 'react';
import { AttendanceRecord } from '../../../common/Interface/AttendanceRecord';
import AttendanceMonthTableTR from './AttendanceMonthTableTR';

interface AttendanceMonthProps {
  attendanceData: AttendanceRecord[];
  onValidDataChange: () => void;
  isManagerPage?: boolean;
}

const AttendanceMonthTable: React.FC<AttendanceMonthProps> = ({
  attendanceData,
  onValidDataChange: onValidDataChange,
  isManagerPage = false,
}) => {
  const validDataChange = () => {
    onValidDataChange();
  };

  return (
    <table className="w-full">
      <thead>
        <tr className="px-4 py-2 bg-blue-900 text-white">
          <th className="w-2">日付</th>
          <th className="">休暇</th>
          <th className="w-8">開始時刻</th>
          <th className="w-8">終了時刻</th>
          <th className=" px-4 py-2">総稼動時間</th>
          <th className=" px-4 py-2">休憩時間</th>
          <th className=" px-4 py-2">実稼動時間</th>
          <th className=" px-4 py-2">備考</th>
          <th className=" px-4 py-2">同期</th>
        </tr>
      </thead>
      <tbody>
        {attendanceData.map((record) => (
          <AttendanceMonthTableTR
            key={record.Date.toDateString() + record.UserID}
            record={record}
            onValidDataChange={validDataChange}
            isManagerPage={isManagerPage}
          />
        ))}
      </tbody>
    </table>
  );
};

export default AttendanceMonthTable;

import React, { useEffect, useState } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { GET_DAILY_RECORD_BY_MONTH, CREATE_REPORT } from '../../../graphql/queries';
import client from '../../../graphql/apolloClient';
import DefaultLayout from '../../../layout/DefaultLayout';
import { AttendanceRecord } from '../../../common/Interface/AttendanceRecord';
import { User, getUsers } from '../../../config/users';
import { useNavigate } from 'react-router-dom';
import AttendanceMonthTable from '../Attendance/AttendanceMonthTable';
import { useMutation } from '@apollo/client';
import { HiDownload } from 'react-icons/hi';

const ManageAttendancePage: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());
  const [selectedMonth, setSelectedMonth] = useState<string>(
    (new Date().getMonth() + 1).toString(),
  );
  const [attendanceData, setAttendanceData] = useState<AttendanceRecord[]>([]);

  const [createReport] = useMutation(CREATE_REPORT);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const isAdmin = attributes['custom:role'] === 'manager';
        console.log(isAdmin);

        if (!isAdmin) {
          navigate('/timecard');
          return;
        }
      } catch (error) {
        console.error('認証エラー:', error);
        navigate('/');
      }
    };
    fetchUser();
  }, [navigate]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsers(getUsers());
      } catch (error) {
        console.error('ユーザー一覧の取得に失敗:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedUserId && selectedMonth && selectedYear) {
        const year = parseInt(selectedYear);
        const month = parseInt(selectedMonth);
        // 指定された年と月を使ってDateオブジェクトを構築し、月初めの午前0時（UTC+09:00）に設定する
        const firstDayOfMonth = new Date(
          `${year}-${month.toString().padStart(2, '0')}-01T00:00:00+09:00`,
        ); // データベースに保存されているUTCフォーマットへの時刻変更
        try {
          const { data } = await client.query({
            query: GET_DAILY_RECORD_BY_MONTH,
            variables: { params: { UserID: selectedUserId, Date: firstDayOfMonth.toISOString() } },
            fetchPolicy: 'network-only', // キャッシュを使用せず、常に新しいデータを取得
          });
          if (data) {
            // ダミーデータとマージするためにデータベースからデータを取得。
            const attendanceDataOfMonth = mergeDummyWithActualData(
              data.getDailyRecordsByMonth || [],
              firstDayOfMonth,
              selectedUserId,
            );
            console.log(attendanceDataOfMonth);
            setAttendanceData(attendanceDataOfMonth);
          } else {
            console.log('No data Present');
            setAttendanceData([]);
          }
        } catch (error) {
          // その日に初めてページにアクセスされたときのみ発生する
          console.log('Error fetching data:', error);
          setAttendanceData([]);
        } finally {
          console.log('Data fetched');
        }
      }
    };
    // This component is mounted, when the intial render is ran once
    fetchData();
  }, [selectedUserId, selectedMonth, selectedYear]);

  const mergeDummyWithActualData = (
    fetchRecords: AttendanceRecord[],
    firstDayOfMonth: Date,
    fetchingUserID: string,
  ): AttendanceRecord[] => {
    const fillRecords: AttendanceRecord[] = [];

    // Create a new Date object for the last Element of the Month
    // 例: Sun Mar 31 2024 00:00:00 GMT+0900 (Japan Standard Time)
    const lastElementOfMonth = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth() + 1,
      0,
    );

    // 月のヌルデータを準備する
    let dummyDay = firstDayOfMonth;
    while (dummyDay <= lastElementOfMonth) {
      // UTCフォーマット
      const newRecord: AttendanceRecord = {
        __typename: 'DailyRecord',
        UserID: fetchingUserID,
        Date: dummyDay,
        Leave: null,
        StartTime: null,
        EndTime: null,
        Note: null,
      };
      fillRecords.push(newRecord);

      // next day
      dummyDay = new Date(dummyDay);
      dummyDay.setDate(dummyDay.getDate() + 1);
    }

    fillRecords.forEach((fillRecord, index) => {
      // Check if the date of the current fillRecord is present in records
      const matchingfetchRecord = fetchRecords.find(
        (fetchRecord) => new Date(fetchRecord.Date).getTime() === fillRecord.Date.getTime(),
      );

      if (matchingfetchRecord) {
        // Nullをデーターで変える
        fillRecords[index] = {
          ...matchingfetchRecord,
          Date: new Date(matchingfetchRecord.Date),
          StartTime: matchingfetchRecord.StartTime ? new Date(matchingfetchRecord.StartTime) : null,
          EndTime: matchingfetchRecord.EndTime ? new Date(matchingfetchRecord.EndTime) : null,
        };
      } else {
        // ヌルのままにしておく
        //console.log('Record not matched.');
      }
    });
    return fillRecords;
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
    setSelectedUserId(event.target.value);
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  const handleDownload = async () => {
    if (!selectedUserId || !selectedMonth || !selectedYear) {
      return;
    }

    setIsDownloading(true);
    try {
      const year = parseInt(selectedYear);
      const month = parseInt(selectedMonth);
      const firstDayOfMonth = new Date(
        `${year}-${month.toString().padStart(2, '0')}-01T00:00:00+09:00`,
      );

      const { data, errors } = await createReport({
        variables: {
          params: {
            UserID: selectedUserId,
            Date: firstDayOfMonth.toISOString(),
          },
        },
      });

      if (errors?.some((error) => error.message.includes('Lambda is initializing your function'))) {
        alert(
          'サーバーの準備中です。1分ほどお待ちいただき、再度お試しください。\n\n' +
            'エラーの詳細:\n' +
            'Lambda関数の初期化中です。まもなく利用可能になります。\n\n' +
            'Original error:\n' +
            errors[0].message,
        );
        return;
      }

      if (data?.createReport?.PresignedURL) {
        window.open(data.createReport.PresignedURL, '_blank');
      }
    } catch (error) {
      console.error('レポート作成エラー:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  //   if (initialLoading || dataLoading) {
  //     return (
  //       <DefaultLayout>
  //         <LoadingIndicator />
  //       </DefaultLayout>
  //     );
  //   }

  return (
    <DefaultLayout>
      <div className="p-6 bg-opacity-75 bg-blue-100 backdrop-blur-lg rounded-md">
        <div className="border bg-blue-900 rounded-lg p-4 mb-4">
          <div className="relative mt-2 flex items-center justify-between">
            <div className="flex gap-4">
              <select
                value={selectedUserId}
                onChange={handleUserChange}
                className="block w-48 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                <option value="">ユーザーを選択してください</option>
                {users.map((user) => (
                  <option key={user.userId} value={user.userId}>
                    {user.username}
                  </option>
                ))}
              </select>

              <select
                value={selectedYear}
                onChange={handleYearChange}
                className="block w-32 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                {Array.from({ length: 5 }, (_, index) => {
                  const year = new Date().getFullYear() - index;
                  return (
                    <option key={year} value={year.toString()}>
                      {year}年
                    </option>
                  );
                })}
              </select>

              <select
                value={selectedMonth}
                onChange={handleMonthChange}
                className="block w-32 rounded-md border-0 py-2.5 pl-4 pr-8 text-black ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 text-sm leading-6 bg-white shadow-sm"
              >
                {Array.from({ length: 12 }, (_, index) => {
                  const monthNumber = index + 1;
                  return (
                    <option key={monthNumber} value={monthNumber.toString()}>
                      {monthNumber}月
                    </option>
                  );
                })}
              </select>
            </div>

            <button
              onClick={handleDownload}
              disabled={!selectedUserId || isDownloading}
              className="flex items-center gap-2 px-4 py-2.5 bg-green-600 text-white rounded-md hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed ml-auto relative"
            >
              {isDownloading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  処理中...
                </>
              ) : (
                <>
                  <HiDownload className="w-5 h-5" />
                  勤怠表DL
                </>
              )}
            </button>
          </div>
        </div>

        {selectedUserId ? (
          attendanceData.length > 0 ? (
            <AttendanceMonthTable
              attendanceData={attendanceData}
              onValidDataChange={() => {
                /* データ更新時の処理 */
              }}
              isManagerPage={true}
            />
          ) : (
            <p className="text-center mt-4">データがありません</p>
          )
        ) : (
          <p className="text-center mt-4">ユーザーを選択してください</p>
        )}
      </div>
    </DefaultLayout>
  );
};

export default ManageAttendancePage;

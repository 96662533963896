import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layout/DefaultLayout';
import ButtonMUI from '@mui/material/Button';
import { getCurrentUser, AuthUser } from 'aws-amplify/auth';
import DigitalClock from '../../../components/DigitalClock/DigitalClock';
import SignInPage from '../../Authentication/SignInPage';
import client from '../../../graphql/apolloClient';
import { useMutation } from '@apollo/client';
import { SAVE_DAILY_RECORDS } from '../../../graphql/mutations';
import { GET_DAILY_RECORD } from '../../../graphql/queries';
import LoadingIndicator from '../../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

const TimeCardPage: React.FC = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [note, setNote] = useState('');
  const [saveDailyRecords] = useMutation(SAVE_DAILY_RECORDS, { client: client });
  const [shiftInTime, setShiftInTime] = useState<string | null>(null);
  const [shiftOutTime, setShiftOutTime] = useState<string | null>(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetching User session
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (error) {
        console.log('Not signed in');
        navigate({
          pathname: '/',
        });
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      // Fetching the data for current date, when the component is mount
      const fetchData = async () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const currentDateISOString = new Date(currentDate).toISOString();
        try {
          const { data } = await client.query({
            query: GET_DAILY_RECORD,
            variables: { params: { UserID: user?.userId, Date: currentDateISOString } },
          });
          if (data && data.getDailyRecord) {
            //If data is present
            const { StartTime, EndTime } = data.getDailyRecord;
            if (StartTime === null && EndTime === null) {
              // 出勤前状態
              setShiftInTime(null);
              setShiftOutTime(null);
            } else if (StartTime === null && EndTime !== null) {
              // 不正な状態
              console.log('StartTime is Not Logged In');
            } else if (StartTime !== null && EndTime === null) {
              // 出勤後状態
              setShiftInTime(StartTime);
              setShiftOutTime(null);
            } else {
              // 退勤後状態
              setShiftInTime(StartTime);
              setShiftOutTime(EndTime);
            }
          } else {
            setShiftInTime(null);
            setShiftOutTime(null);
          }
        } catch (error) {
          setShiftInTime(null);
          setShiftOutTime(null);
        } finally {
          setDataLoading(false);
        }
      };
      fetchData();
    }
  }, [user]);

  const handleShiftInTimeClick = async () => {
    const newShiftInTime = new Date().toISOString();
    await saveStartTime(newShiftInTime, null);
  };

  const handleShiftOutTimeClick = async () => {
    setShowConfetti(true);
    const newShiftOutTime = new Date().toISOString();
    await saveEndTime(newShiftOutTime);
  };

  // Using this function to save the StartTime
  const saveStartTime = async (startTime: string | null, endTime: string | null) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    try {
      setDataLoading(true);
      const response = await saveDailyRecords({
        variables: {
          params: [
            {
              UserID: user?.userId,
              Date: currentDate.toISOString(), // Set the current date with time set to 00:00:00
              Leave: null,
              StartTime: startTime,
              EndTime: endTime,
              Note: note,
            },
          ],
        },
      });

      if (response.data.saveDailyRecords && response.data.saveDailyRecords.length > 0) {
        // It will Enter here only, When the Entry is made in Database
        const savedRecord = response.data.saveDailyRecords[0];
        const savedDate = new Date(savedRecord.Date).toLocaleDateString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        });
        const savedStartTime = new Date(savedRecord.StartTime).toLocaleTimeString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        });
        const savedEndTime = '';
        const alertMessage = `Saved Records:\nDate: ${savedDate}\nStartTime: ${savedStartTime}\nEndTime: ${savedEndTime}\nNote: ${savedRecord.Note}`;

        // It will give Acknowledgment to user about what changes has been made
        console.log(alertMessage);

        // UI Update
        setShiftInTime(startTime);
        setShiftOutTime(null);
      } else {
        // When the above query is not able to write or there is error in between while writing on database
        console.log('Error saving Shift In Time. Please retry. Checking the database for updates.');
      }
    } catch (error) {
      // This error when not able to make connection to database, and therefore not able to write on database
      console.log('Shift-In Catch: Error saving Shift In Time.');
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };

  const saveEndTime = async (endTime: string | null) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    try {
      setDataLoading(true);
      console.log('Value of ShiftInTime inside Try Block--->', shiftInTime);
      const response = await saveDailyRecords({
        variables: {
          params: [
            {
              UserID: user?.userId,
              Date: currentDate.toISOString(), // Set the current date with time set to 00:00:00
              Leave: null,
              StartTime: shiftInTime,
              EndTime: endTime,
              Note: note,
            },
          ],
        },
      });

      if (response.data.saveDailyRecords && response.data.saveDailyRecords.length > 0) {
        // For the Acknowledgement, after saving EndTime
        const savedRecord = response.data.saveDailyRecords[0];
        const savedDate = new Date(savedRecord.Date).toLocaleDateString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        });
        const savedStartTime = new Date(savedRecord.StartTime).toLocaleTimeString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        });
        const savedEndTime = new Date(savedRecord.EndTime).toLocaleTimeString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        });
        const alertMessage = `Saved Records:\nDate: ${savedDate}\nStartTime: ${savedStartTime}\nEndTime: ${savedEndTime}\nNote: ${savedRecord.Note}`;
        console.log(alertMessage);

        // UI Update
        setShiftOutTime(endTime);
      } else {
        console.log(
          'Error saving Shift Out Time. Please retry. Checking the database for updates.',
        );
      }
    } catch (error) {
      console.log('Error saving Shift Out Time. Please retry. Checking the database for updates.');
    } finally {
      setDataLoading(false);
    }
  };

  //**************************************************************************************************************************************//
  if (dataLoading) {
    // Loading with Default
    return (
      <DefaultLayout>
        <LoadingIndicator />
      </DefaultLayout>
    );
  }

  if (user !== null) {
    return (
      <DefaultLayout>
        {/* Todo: Adjust the horizontal spacing of the box*/}
        <div className="dark:bg-boxdark-2 dark:text-bodydark h-screen flex justify-center items-start  pt-16 ">
          <main className="p-6 border border-gray-200 rounded-lg bg-blue-300 shadow-md">
            {showConfetti && <Confetti />}
            <DigitalClock />
            <div className="mt-8 flex flex-col items-center">
              <div className="mb-5">
                <ButtonMUI
                  type="submit"
                  onClick={handleShiftInTimeClick}
                  color="primary"
                  variant="contained"
                  size="large"
                  className="w-32"
                  disabled={shiftInTime !== null}
                >
                  出勤
                </ButtonMUI>
              </div>
              <div className="mb-5">
                <ButtonMUI
                  type="submit"
                  onClick={handleShiftOutTimeClick}
                  color="warning"
                  variant="contained"
                  size="large"
                  className="w-32"
                  disabled={shiftOutTime !== null}
                >
                  退勤
                </ButtonMUI>
              </div>
              <div className="flex justify-evenly w-full mt-8">
                <input
                  type="text"
                  value={note}
                  onChange={(event) => {
                    setNote(event.target.value); // Save the data
                  }}
                  placeholder="Add notes..."
                  className="p-3 border border-gray-300 rounded-lg w-96"
                />
              </div>
            </div>
          </main>
        </div>
      </DefaultLayout>
    );
  } else {
    return <SignInPage />;
  }
};

export default TimeCardPage;

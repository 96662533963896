// apolloClient.ts

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsConfig from '../aws-exports';

// Create HTTP link to AWS AppSync endpoint
const httpLink = createHttpLink({
  uri: awsConfig.aws_appsync_graphqlEndpoint,
});

// Set context for authorization headers
const authLink = setContext(async (_, { headers }) => {
  try {
    // Get current authenticated user
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${idToken}` : '',
        },
      };
    } catch (error) {
      console.error('Error retrieving JWT token:', error);
    }
  } catch (error) {
    console.log('*****Some Error****', error);
  }
});

// Create Apollo Client instance
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;

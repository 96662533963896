import React from 'react';

const LoadingComponent: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
        <svg
          className="animate-spin h-8 w-8 text-gray-600 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.004 8.004 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm2-10.582l3 2.647A8.004 8.004 0 0112 4V0c-4.418 0-8 3.582-8 8h4zm10 6.291A8.004 8.004 0 0120 12h4c0-4.418-3.582-8-8-8v4zm-10 4.291l-3-2.647C1.135 16.824 0 14.042 0 11.999h4c0 2.083.793 3.985 2.101 5.434zM12 20a8 8 0 008-8h-4c0 2.083-.793 3.985-2.101 5.434l-3-2.647z"
          ></path>
        </svg>
        <p className="text-gray-700 text-center mt-4">Loading...</p>
      </div>
    </div>
  );
};

export default LoadingComponent;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Importing AWS-Amplify components
import { getCurrentUser, AuthUser } from '@aws-amplify/auth';

// Importing components from the Project
import DefaultLayout from '../../layout/DefaultLayout';
import Photograph from '../../images/user/user-01.png';

const DashboardPage: React.FC = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const currentUser = await getCurrentUser();
        setUser(currentUser);
      } catch (error) {
        console.error(error);
        console.log('Not signed in');
        navigate({
          pathname: '/',
        });
      }
    };
    getUser();
  }, [navigate]); // Run the effect once on initial render

  if (user !== null) {
    return (
      <DefaultLayout>
        <div className="dark:bg-boxdark-2 dark:text-bodydark">
          <div className="flex h-screen overflow-hidden">
            <main className="bg-gray-100 dark:bg-gray-800 py-12 px-4">
              <p className="text-lg text-gray-700 dark:text-gray-300 mb-4">
                <h3>ようこそ......！</h3>
                <img src={Photograph} alt="Japan" />
                <h3>従業員名: {user.username}</h3>
              </p>
            </main>
          </div>
        </div>
      </DefaultLayout>
    );
  } else {
    navigate({
      pathname: '/',
    });
    return null;
  }
};

export default DashboardPage;

import { gql } from '@apollo/client';

// Query for Getting Daily Record
export const GET_DAILY_RECORD = gql`
  query GetDailyRecord($params: getDailyRecordInput!) {
    getDailyRecord(params: $params) {
      UserID
      Date
      Leave
      StartTime
      EndTime
      Note
    }
  }
`;

// Query for Getting Records by Month
export const GET_DAILY_RECORD_BY_MONTH = gql`
  query GetDailyRecordsByMonth($params: getDailyRecordInput!) {
    getDailyRecordsByMonth(params: $params) {
      UserID
      Date
      Leave
      StartTime
      EndTime
      Note
    }
  }
`;

// Query for Creating Report
export const CREATE_REPORT = gql`
  mutation CreateReport($params: DailyRecordsInput!) {
    createReport(params: $params) {
      PresignedURL
    }
  }
`;

import { gql } from '@apollo/client';

export const SAVE_DAILY_RECORDS = gql`
  mutation SaveDailyRecords($params: [DailyRecordInput]) {
    saveDailyRecords(params: $params) {
      UserID
      Date
      Leave
      StartTime
      EndTime
      Note
    }
  }
`;

const awsConfigBase = {
  aws_project_region: 'ap-northeast-1',
  aws_appsync_region: 'ap-northeast-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'ap-northeast-1',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

const awsConfigDev = {
  aws_appsync_graphqlEndpoint:
    'https://ek25v6yxtngxdg7jwtpbcdndmi.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_cognito_identity_pool_id: 'ap-northeast-1:63b6687a-991a-4eef-b09d-55494f97f563',
  aws_user_pools_id: 'ap-northeast-1_rsNuM5qNS',
  aws_user_pools_web_client_id: '2v3c4iekm618n5vdacvo5383bj',
};

const awsConfigStg = {
  aws_appsync_graphqlEndpoint:
    'https://37eek4ogl5gutmss5uiz4hsaoy.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  aws_cognito_identity_pool_id: 'ap-northeast-1:047025a0-45c3-40fa-b7ef-331bb0751688',
  aws_user_pools_id: 'ap-northeast-1_aY0UowGOB',
  aws_user_pools_web_client_id: '4nq8o02qfn2mo003do9d1m4vmc',
};

const awsConfig = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'stg':
      return { ...awsConfigBase, ...awsConfigStg };
    case 'dev':
    default:
      return { ...awsConfigBase, ...awsConfigDev };
  }
})();

export default awsConfig;
